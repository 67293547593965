import { HomeIcon, UserIcon } from '@heroicons/react/24/outline';
import SidebarItemsComp from '../Common/SidebarItemsComp';

const WebsiteSidebarItems = ({ mobile, setSidebarOpen }) => {
   const primarySidebarItems = [
      {
         name: 'Home',
         href: '/website',
         svg: HomeIcon,
         end: true,
      },
      { name: 'Homepage', href: '/website/homepage', svg: HomeIcon },
      { name: 'Sponsors', href: '/website/sponsors', svg: UserIcon },
   ];
   return (
      <SidebarItemsComp
         mobile={mobile}
         primarySidebarItems={primarySidebarItems}
         setSidebarOpen={setSidebarOpen}
      />
   );
};

export default WebsiteSidebarItems;
