import { PlusSmallIcon } from '@heroicons/react/20/solid';
import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import FilterDropdown from '../../../UI/FilterDropdown';
import useAuth from '../../../../hooks/useAuth';
import jwtDecode from 'jwt-decode';

const AttendanceComp = ({
   registrations,
   schoolYear,
   schoolClasses,
   currentSchoolYear,
}) => {
   const { auth } = useAuth();

   const decoded = 'accessToken' in auth && jwtDecode(auth?.accessToken);

   const isUserTeacher = decoded?.UserInfo?.roles?.includes('teacher');

   const [yearFilter, setYearFilter] = useState({});
   const [classFilter, setClassFilter] = useState({});
   const [sortBy, setSortBy] = useState('absence');

   function sortByName() {
      if (sortBy === 'absence' || sortBy === 'firstname') {
         setSortBy('lastname');
      } else if (sortBy === 'lastname') {
         setSortBy('firstname');
      }
   }

   const yearFilteredRegistrations = useMemo(
      () =>
         !yearFilter.value
            ? registrations
            : registrations.filter(registration => {
                 return registration?.schoolYear === yearFilter?.value?._id;
              }),
      [registrations, yearFilter?.value]
   );

   const classFilteredRegistrations = useMemo(
      () =>
         !classFilter.value
            ? yearFilteredRegistrations
            : yearFilteredRegistrations.filter(registration => {
                 return (
                    registration?.schoolClass?._id === classFilter?.value?._id
                 );
              }),
      [classFilter?.value, yearFilteredRegistrations]
   );

   const sortedRegistrations = useMemo(
      () =>
         classFilteredRegistrations.sort((a, b) =>
            sortBy === 'lastname'
               ? a?.student?.lastname
                    .toString()
                    .trim()
                    .localeCompare(b?.student?.lastname?.toString()?.trim())
               : sortBy === 'firstname'
               ? a?.student?.firstname
                    .toString()
                    .trim()
                    .localeCompare(b?.student?.firstname?.toString()?.trim())
               : sortBy === 'absence'
               ? a?.absences.length > b?.absences.length
                  ? -1
                  : b?.absences.length > a?.absences.length
                  ? 1
                  : 0
               : a?.student?.lastname < b?.student?.lastname
               ? -1
               : b?.student?.lastname < a?.student?.lastname
               ? 1
               : 0
         ),
      [classFilteredRegistrations, sortBy]
   );

   const yearDropdownItems = useMemo(
      () => [
         { name: 'All', value: '' },
         ...schoolYear.map(year => ({
            name: year?.yearName,
            value: year,
         })),
      ],
      [schoolYear]
   );

   const classDropdownItems = useMemo(
      () => [
         { name: 'All', value: '' },
         ...schoolClasses
            .sort((a, b) =>
               a?.className < b?.className
                  ? -1
                  : b?.className < a?.className
                  ? 1
                  : 0
            )
            .map(eachClass => ({
               name: eachClass?.className,
               value: eachClass,
            })),
      ],
      [schoolClasses]
   );

   return (
      <main className='flex-1'>
         {/* <!-- BREADCRUMBS --> */}
         <nav
            className='bg-white border-b border-gray-200 flex'
            aria-label='Breadcrumb'
         >
            <ol className='max-w-screen-xl w-full mx-auto px-4 flex space-x-4 sm:px-6 lg:px-8'>
               <li className='flex'>
                  <div className='flex items-center'>
                     <Link
                        to='/religious-education'
                        className='text-gray-400 hover:text-gray-500'
                     >
                        {/* <!-- Heroicon name: mini/home --> */}
                        <svg
                           className='flex-shrink-0 h-5 w-5'
                           xmlns='http://www.w3.org/2000/svg'
                           viewBox='0 0 20 20'
                           fill='currentColor'
                           aria-hidden='true'
                        >
                           <path
                              fillRule='evenodd'
                              d='M9.293 2.293a1 1 0 011.414 0l7 7A1 1 0 0117 11h-1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-3a1 1 0 00-1-1H9a1 1 0 00-1 1v3a1 1 0 01-1 1H5a1 1 0 01-1-1v-6H3a1 1 0 01-.707-1.707l7-7z'
                              clipRule='evenodd'
                           />
                        </svg>
                        <span className='sr-only'>Home</span>
                     </Link>
                  </div>
               </li>

               <li className='flex'>
                  <div className='flex items-center'>
                     <svg
                        className='flex-shrink-0 w-6 h-full text-gray-200'
                        viewBox='0 0 24 44'
                        preserveAspectRatio='none'
                        fill='currentColor'
                        xmlns='http://www.w3.org/2000/svg'
                        aria-hidden='true'
                     >
                        <path d='M.293 0l22 22-22 22h1.414l22-22-22-22H.293z' />
                     </svg>
                     <Link
                        to='/religious-education/attendance'
                        className='ml-4 text-sm font-light text-gray-500 hover:text-gray-700'
                     >
                        Attendance Records
                     </Link>
                  </div>
               </li>
            </ol>
            <div className='mr-10 mt-3'>
               <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  strokeWidth='1.5'
                  stroke='currentColor'
                  className='w-6 h-6'
               >
                  <path
                     strokeLinecap='round'
                     strokeLinejoin='round'
                     d='M10.5 6h9.75M10.5 6a1.5 1.5 0 11-3 0m3 0a1.5 1.5 0 10-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-9.75 0h9.75'
                  />
               </svg>
            </div>
         </nav>

         {/* <!--attendance table--> */}
         <div className='px-4 sm:px-6 lg:px-8 mt-10 mb-40'>
            <div className='sm:flex sm:items-center'>
               <div className='sm:flex-auto'>
                  <h2 className='text-lg font-semibold text-gray-900'>
                     Student Attendance
                  </h2>
                  <p className='mt-2 text-sm text-gray-700'>
                     From here you can the number of missed classes per student.
                  </p>
               </div>
               {/* <!-- Dropdown end --> */}

               {/* <!--filter for dates--> */}
               <div>
                  <FilterDropdown
                     items={yearDropdownItems}
                     selectedState={[yearFilter, setYearFilter]}
                     defaultValue='School Year'
                     selectedValue={currentSchoolYear}
                  />
               </div>
               <div>
                  {schoolClasses.length > 1 && (
                     <FilterDropdown
                        items={classDropdownItems}
                        selectedState={[classFilter, setClassFilter]}
                        defaultValue='Class'
                     />
                  )}
               </div>

               <div className='mt-4 sm:mt-0 sm:ml-16 sm:flex-none'>
                  <button
                     type='button'
                     className='inline-flex items-center p-1.5 border border-transparent rounded-full shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                  >
                     {/* <!-- Heroicon name: solid/plus-sm --> */}
                     <Link
                        to={
                           isUserTeacher
                              ? '/re/teacher/add-attendance'
                              : '/religious-education/add-attendance'
                        }
                     >
                        <PlusSmallIcon className='h-5 w-5' />
                     </Link>
                  </button>
               </div>
            </div>
            <div className='mt-10 mb-40 flex flex-col'>
               <div className='-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8'>
                  <div className='inline-block min-w-full py-2 align-middle md:px-6 lg:px-8'>
                     <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
                        <table className='min-w-full divide-y divide-gray-300'>
                           <thead className='bg-gray-50'>
                              <tr>
                                 <th
                                    scope='col'
                                    className={`cursor-pointer px-3 py-3.5 text-left text-sm font-semibold text-gray-900${
                                       sortBy === 'firstname' ||
                                       sortBy === 'lastname'
                                          ? ' bg-gray-200'
                                          : ''
                                    }`}
                                 >
                                    <div className='flex items-center'>
                                       Name
                                       <svg
                                          xmlns='http://www.w3.org/2000/svg'
                                          fill='none'
                                          viewBox='0 0 24 24'
                                          strokeWidth='1.5'
                                          stroke='currentColor'
                                          onClick={sortByName}
                                          className='w-4 h-4 whitespace-nowrap ml-2 cursor-pointer hover:text-indigo-500'
                                       >
                                          <path
                                             strokeLinecap='round'
                                             strokeLinejoin='round'
                                             d='M3 7.5L7.5 3m0 0L12 7.5M7.5 3v13.5m13.5 0L16.5 21m0 0L12 16.5m4.5 4.5V7.5'
                                          />
                                       </svg>
                                    </div>
                                 </th>

                                 <th
                                    scope='col'
                                    className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                                 >
                                    Class
                                 </th>
                                 <th
                                    scope='col'
                                    className={`cursor-pointer px-3 py-3.5 text-left text-sm font-semibold text-gray-900${
                                       sortBy === 'absence' && ' bg-gray-200'
                                    }`}
                                    onClick={() => setSortBy('absence')}
                                 >
                                    Missed Classes
                                 </th>
                              </tr>
                           </thead>
                           <tbody className='divide-y divide-gray-200 bg-white'>
                              {sortedRegistrations.map(registration => (
                                 <tr
                                    className='hover:bg-gray-100'
                                    key={registration?._id}
                                 >
                                    <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6'>
                                       {sortBy === 'lastname'
                                          ? `${
                                               registration?.student?.lastname
                                            },${
                                               registration?.student?.middlename
                                                  ? ' ' +
                                                    registration?.student
                                                       ?.middlename
                                                  : ''
                                            } ${
                                               registration?.student?.firstname
                                            }`
                                          : `${
                                               registration?.student?.firstname
                                            }${
                                               registration?.student?.middlename
                                                  ? ' ' +
                                                    registration?.student
                                                       ?.middlename
                                                  : ''
                                            } ${
                                               registration?.student?.lastname
                                            }`}
                                    </td>

                                    <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                       {registration?.schoolClass?.className ||
                                          ''}
                                    </td>

                                    <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                       {registration?.absences
                                          ? registration?.absences?.length
                                          : '0'}
                                    </td>
                                 </tr>
                              ))}
                           </tbody>
                        </table>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </main>
   );
};

export default AttendanceComp;
