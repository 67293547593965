import { Fragment } from 'react';
import logoSvg from '../../assets/Artboard_1.svg';
import logo_dark from '../../assets/logo-dark.svg';
import { Transition } from '@headlessui/react';

import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import useSearch from '../../hooks/useSearch';
import UserProfileDropdown from '../Common/UserProfileDropdown';
import { XMarkIcon } from '@heroicons/react/24/outline';
import SidebarRoutes from './SidebarRoutes';
import { useNavigate } from 'react-router-dom';

const Sidebar = ({ sidebarOpen, setSidebarOpen }) => {
   const { searchInputAttr } = useSearch();
   const navigate = useNavigate();

   const navigateToDashboard = () => {
      navigate('/dashboard');
   };

   return (
      <>
         <div
            className='relative z-50 lg:hidden'
            role='dialog'
            aria-modal='true'
         >
            <div>
               <Transition
                  show={sidebarOpen}
                  as={Fragment}
                  enter='transition-opacity ease-linear duration-300'
                  enterFrom='opacity-0'
                  enterTo='opacity-100'
                  leave='transition-opacity ease-linear duration-300'
                  leaveFrom='opacity-100'
                  leaveTo='opacity-0'
               >
                  <div className='fixed inset-0 bg-gray-600 bg-opacity-75'></div>
               </Transition>

               <Transition
                  show={sidebarOpen}
                  as={Fragment}
                  enter='transition ease-in-out duration-300 transform'
                  enterFrom='-translate-x-full'
                  enterTo='translate-x-0'
                  leave='transition ease-in-out duration-300 transform'
                  leaveFrom='translate-x-0'
                  leaveTo='-translate-x-full'
               >
                  <div className='fixed inset-0 flex z-30'>
                     <div className='relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-white'>
                        <Transition
                           show={sidebarOpen}
                           as={Fragment}
                           enter='transition-opacity ease-in-out duration-300'
                           enterFrom='opacity-0'
                           enterTo='opacity-100'
                           leave='transition-opacity ease-in-out duration-300'
                           leaveFrom='opacity-100'
                           leaveTo='opacity-0'
                        >
                           <div className='absolute top-0 right-0 -mr-12 pt-2'>
                              <button
                                 onClick={() => setSidebarOpen(false)}
                                 type='button'
                                 className='ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white'
                              >
                                 <span className='sr-only'>Close sidebar</span>
                                 <XMarkIcon className='h-6 w-6 text-white' />
                              </button>
                           </div>
                        </Transition>

                        <div className='flex-shrink-0 flex items-center px-4'>
                           <img
                              className='h-8 w-auto cursor-pointer'
                              src={logo_dark}
                              alt='Sacramatic Logo'
                              onClick={() => {
                                 setSidebarOpen(false);
                                 navigateToDashboard();
                              }}
                           />
                        </div>
                        <div className='mt-5 flex-1 h-0 overflow-y-auto'>
                           <SidebarRoutes
                              setSidebarOpen={setSidebarOpen}
                              mobile
                           />
                        </div>
                     </div>
                     <div className='flex-shrink-0 w-14' aria-hidden='true'>
                        {/*  Dummy element to force sidebar to shrink to fit close icon  */}
                     </div>
                  </div>
               </Transition>
            </div>
         </div>

         {/*  Static sidebar for desktop  */}
         <div className='hidden z-30 lg:flex lg:flex-col lg:w-64 lg:fixed lg:inset-y-0 lg:border-r lg:border-gray-200 lg:pt-5 lg:pb-4 lg:bg-gray-700'>
            <div className='flex items-center flex-shrink-0 px-6'>
               <img
                  className='h-8 w-auto text-gray-400 cursor-pointer'
                  src={logoSvg}
                  alt='Sacramatic Logo'
                  onClick={navigateToDashboard}
               />
            </div>
            {/*  Sidebar component, swap this element with another sidebar if you like  */}
            <div className='mt-6 h-0 flex-1 flex flex-col overflow-y-auto'>
               <UserProfileDropdown />
               {/*  Sidebar Search  */}
               <div className='px-3 mt-5'>
                  <label htmlFor='search' className='sr-only'>
                     Search 6
                  </label>
                  <div className='mt-1 relative rounded-md shadow-sm'>
                     <div
                        className='absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none'
                        aria-hidden='true'
                     >
                        <MagnifyingGlassIcon className='mr-3 h-4 w-4 text-gray-400' />
                     </div>
                     <input
                        type='search'
                        {...searchInputAttr}
                        className='focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-9 sm:text-sm bg-gray-700 text-gray-300 border-gray-300 rounded-md'
                        placeholder='Search'
                     />
                  </div>
               </div>
               {/*  Navigation  */}
               <SidebarRoutes />
            </div>
         </div>
      </>
   );
};

export default Sidebar;
