import { Fragment } from 'react';
import {
   Menu,
   MenuButton,
   MenuItem,
   MenuItems,
   Transition,
} from '@headlessui/react';
import useLogout from '../../hooks/useLogout';
import { ChevronUpDownIcon } from '@heroicons/react/20/solid';
import useAuth from '../../hooks/useAuth';
import { Link } from 'react-router-dom';

type TDropdownItem = {
   url: string;
   title: string;
   hidden?: boolean;
};

export default function TeacherProfileDropdown({
   mobile,
}: {
   mobile?: boolean;
}) {
   const logout = useLogout();
   const { auth } = useAuth();

   // const name =
   //    !auth?.firstname && !auth?.lastname
   //       ? auth?.user
   //       : `${auth?.firstname} ${auth?.lastname}`;

   const menuClassName = {
      className: mobile
         ? 'relative ml-3'
         : 'px-3 relative inline-block text-left',
   };

   const menuItems1: TDropdownItem[] = [
      { url: '/re/teacher', title: 'Teacher Dashboard' },
   ];
   const menuItems2: TDropdownItem[] = [];

   return (
      <Menu as='div' {...menuClassName}>
         <div>
            {mobile ? (
               <MenuButton
                  type='button'
                  className='max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500'
                  id='user-menu-button'
                  aria-expanded='false'
                  aria-haspopup='true'
               >
                  <span className='sr-only'>Open user menu</span>
                  {'photo' in auth && auth?.photo?.src ? (
                     <img
                        className='h-8 w-8 rounded-full'
                        src={auth.photo.src}
                        loading='lazy'
                        alt=''
                     />
                  ) : (
                     <div className='h-8 w-8 rounded-full flex items-center justify-center bg-gray-500'>
                        <span className='text-gray-50'>
                           {'firstname' in auth && auth?.firstname
                              ? auth.firstname[0]
                              : ''}
                        </span>
                     </div>
                  )}
               </MenuButton>
            ) : (
               <MenuButton
                  type='button'
                  className='group w-full bg-gray-600 rounded-md px-3.5 py-2 text-sm text-left font-medium text-gray-700 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-purple-500'
                  id='options-menu-button'
                  aria-expanded='false'
                  aria-haspopup='true'
               >
                  <span className='flex w-full justify-between items-center'>
                     <span className='flex min-w-0 items-center justify-between space-x-3'>
                        {'photo' in auth && auth?.photo?.src ? (
                           <img
                              className='w-10 h-10 bg-gray-300 rounded-full flex-shrink-0'
                              src={auth.photo.src}
                              loading='lazy'
                              alt=''
                           />
                        ) : (
                           <div className='h-10 w-10 rounded-full flex items-center justify-center bg-gray-300'>
                              <span className='text-gray-700'>
                                 {'firstname' in auth && auth?.firstname
                                    ? auth.firstname[0]
                                    : ''}
                              </span>
                           </div>
                        )}
                        <span className='flex-1 flex flex-col min-w-0'>
                           <span className='text-gray-300 text-sm font-medium truncate'>
                              Teacher Dashboard
                           </span>
                           <span className='text-gray-400 text-sm truncate'>
                              {'user' in auth ? auth?.user : ''}
                           </span>
                        </span>
                     </span>
                     <ChevronUpDownIcon className='flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-500' />
                  </span>
               </MenuButton>
            )}
         </div>
         <Transition
            as={Fragment}
            enter='transition ease-out duration-100'
            enterFrom='transform opacity-0 scale-95'
            enterTo='transform opacity-100 scale-100'
            leave='transition ease-in duration-75'
            leaveFrom='transform opacity-100 scale-100'
            leaveTo='transform opacity-0 scale-95'
         >
            <MenuItems
               className='origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none z-10'
               aria-orientation='vertical'
               aria-labelledby='user-menu-button'
            >
               {menuItems1.length > 0 && (
                  <div className='py-1' role='none'>
                     {menuItems1
                        .filter(item => !item?.hidden)
                        .map((item, index) => (
                           <MenuItem key={item.title + (index + 1).toString()}>
                              <Link
                                 to={item.url}
                                 className='text-gray-700 block px-4 py-2 text-sm'
                                 role='menuitem'
                                 tabIndex={-1}
                                 id={`user-menu-item-${index}`}
                              >
                                 {item.title}
                              </Link>
                           </MenuItem>
                        ))}
                  </div>
               )}
               {menuItems2.length > 0 && (
                  <div className='py-1' role='none'>
                     {menuItems2.map((item, index) => (
                        <MenuItem key={item.title + (index + 1).toString()}>
                           <Link
                              to={item.url}
                              className='text-gray-700 block px-4 py-2 text-sm'
                              role='menuitem'
                              tabIndex={-1}
                              id={`user-menu-item-${menuItems1.length + index}`}
                           >
                              {item.title}
                           </Link>
                        </MenuItem>
                     ))}
                  </div>
               )}
               <div className='py-1' role='none'>
                  <MenuItem>
                     <button
                        className='text-gray-700 block px-4 py-2 text-sm'
                        role='menuitem'
                        tabIndex={-1}
                        id='user-menu-item-0'
                        onClick={() => logout()}
                     >
                        Logout
                     </button>
                  </MenuItem>
               </div>
            </MenuItems>
         </Transition>
      </Menu>
   );
}
