import { Bars3CenterLeftIcon } from '@heroicons/react/24/outline';
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import useSearch from '../../hooks/useSearch';
import UserProfileDropdown from '../Common/UserProfileDropdown';

const MobileHeader = ({ setSidebar }) => {
   const { searchInputAttr } = useSearch();
   return (
      <div className='sticky top-0 z-20 flex-shrink-0 flex h-16 bg-white border-b border-gray-200 lg:hidden'>
         {/* <!-- Sidebar toggle, controls the 'sidebarOpen' sidebar state. --> */}
         <button
            onClick={() => setSidebar(prev => !prev)}
            type='button'
            className='px-4 border-r cursor-pointer border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-purple-500 lg:hidden'
         >
            <span className='sr-only'>Open sidebar</span>
            <Bars3CenterLeftIcon className='h-6 w-6' />
         </button>
         <div className='flex-1 flex justify-between px-4 sm:px-6 lg:px-8'>
            <div className='flex-1 flex'>
               <form className='w-full flex md:ml-0' action='#' method='GET'>
                  <label htmlFor='search-field' className='sr-only'>
                     Search
                  </label>
                  <div className='relative w-full text-gray-400 focus-within:text-gray-600'>
                     <div className='absolute inset-y-0 left-0 flex items-center pointer-events-none'>
                        <MagnifyingGlassIcon className='h-5 w-5' />
                     </div>
                     <input
                        className='block w-full h-full pl-8 pr-3 py-2 border-transparent text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-0 focus:border-transparent focus:placeholder-gray-400 sm:text-sm'
                        placeholder='Search'
                        type='search'
                        {...searchInputAttr}
                     />
                  </div>
               </form>
            </div>
            <div className='flex items-center'>
               {/* <!-- Profile dropdown --> */}
               <UserProfileDropdown mobile />
            </div>
         </div>
      </div>
   );
};

export default MobileHeader;
