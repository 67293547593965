import {
   DocumentPlusIcon,
   DocumentIcon,
   HomeIcon,
} from '@heroicons/react/24/outline';
import SidebarItemsComp from '../Common/SidebarItemsComp';

type Props = {
   mobile: boolean;
   setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const BaptismsSidebarItems = ({ setSidebarOpen, mobile }: Props) => {
   const primarySidebarItems = [
      {
         name: 'Dashboard',
         href: '/baptisms',
         svg: HomeIcon,
         end: true,
      },
      {
         name: 'Baptism Dates',
         href: '/baptisms/dates',
         svg: DocumentIcon,
      },
      {
         name: 'Add Baptism',
         href: '/baptisms/add-baptism',
         svg: DocumentPlusIcon,
      },
      {
         title: 'REPORTS',
         type: 'header',
         items: [
            {
               name: 'Notes Report',
               href: '/baptisms/reports/notes-report',
               svg: DocumentIcon,
            },
         ],
      },
   ];

   return (
      <SidebarItemsComp
         mobile={mobile}
         primarySidebarItems={primarySidebarItems}
         setSidebarOpen={setSidebarOpen}
      />
   );
};

export default BaptismsSidebarItems;
