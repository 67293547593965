import { CogIcon } from '@heroicons/react/24/outline';
import SidebarItemsComp from '../Common/SidebarItemsComp';

const SettingsSidebarItems = ({ mobile, setSidebarOpen }) => {
   const primarySidebarItems = [
      {
         name: 'Settings',
         href: '/settings',
         svg: CogIcon,
         end: true,
      },
   ];
   return (
      <SidebarItemsComp
         mobile={mobile}
         primarySidebarItems={primarySidebarItems}
         setSidebarOpen={setSidebarOpen}
      />
   );
};

export default SettingsSidebarItems;
