import { DocumentTextIcon } from '@heroicons/react/20/solid';
import { TStudentResponse } from '../../../types/ReligiousEducation/Student';
import { getZoneDate } from '../../../utils/dateFunctions';

type Props = {
   student: TStudentResponse;
};
export default function SacramentalTable({ student }: Props) {
   return (
      <div className='px-4 sm:px-6 lg:px-8 mt-10'>
         <div className='sm:flex sm:items-center'>
            <div className='sm:flex-auto'>
               <h2 className='text-lg font-semibold text-gray-900'>
                  Sacramental Records
               </h2>
            </div>

            {/* <div className='mt-4 sm:mt-0 sm:ml-16 sm:flex-none'>
               <button
                  type='button'
                  className='inline-flex items-center p-1.5 border border-transparent rounded-full shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
               > */}
            {/* <!-- Heroicon name: solid/plus-sm --> */}
            {/* <Link to='edit-sacraments'>
                     <svg
                        xmlns='http://www.w3.org/2000/svg'
                        fill='none'
                        viewBox='0 0 24 24'
                        strokeWidth='1.5'
                        stroke='currentColor'
                        className='w-5 h-5'
                     >
                        <path
                           strokeLinecap='round'
                           strokeLinejoin='round'
                           d='M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z'
                        />
                        <path
                           strokeLinecap='round'
                           strokeLinejoin='round'
                           d='M15 12a3 3 0 11-6 0 3 3 0 016 0z'
                        />
                     </svg>
                  </Link>
               </button>
            </div> */}
         </div>
         <div className='mt-5 flex flex-col'>
            <div className='-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8'>
               <div className='inline-block min-w-full py-2 align-middle md:px-6 lg:px-8'>
                  <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
                     <table className='min-w-full divide-y divide-gray-300'>
                        <thead className='bg-gray-50'>
                           <tr>
                              <th
                                 scope='col'
                                 className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                              >
                                 Sacrament
                              </th>
                              <th
                                 scope='col'
                                 className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                              >
                                 Status
                              </th>
                              <th
                                 scope='col'
                                 className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                              >
                                 Date
                              </th>
                              <th
                                 scope='col'
                                 className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                              >
                                 Church
                              </th>
                              <th
                                 scope='col'
                                 className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                              >
                                 Docs
                              </th>
                           </tr>
                        </thead>

                        <tbody className='divide-y divide-gray-200 bg-white'>
                           {/* <!-- Odd row --> */}
                           <tr className='hover:bg-gray-100'>
                              <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6'>
                                 Baptized
                              </td>
                              {!student?.baptized ||
                              student?.baptized.toLowerCase() === 'no' ? (
                                 <td className='whitespace-nowrap px-3 py-4 text-sm text-red-500'>
                                    <svg
                                       xmlns='http://www.w3.org/2000/svg'
                                       className='h-6 w-6'
                                       fill='none'
                                       viewBox='0 0 24 24'
                                       stroke='currentColor'
                                       strokeWidth='2'
                                    >
                                       <path
                                          strokeLinecap='round'
                                          strokeLinejoin='round'
                                          d='M6 18L18 6M6 6l12 12'
                                       />
                                    </svg>
                                 </td>
                              ) : (
                                 <td className='whitespace-nowrap px-3 py-4 text-sm text-green-500'>
                                    <svg
                                       xmlns='http://www.w3.org/2000/svg'
                                       className='h-6 w-6'
                                       fill='none'
                                       viewBox='0 0 24 24'
                                       stroke='currentColor'
                                       strokeWidth='2'
                                    >
                                       <path
                                          strokeLinecap='round'
                                          strokeLinejoin='round'
                                          d='M5 13l4 4L19 7'
                                       />
                                    </svg>
                                 </td>
                              )}
                              <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                 {student?.baptizedDate
                                    ? getZoneDate(
                                         student.baptizedDate
                                      ).toLocaleDateString('en-us', {
                                         month: '2-digit',
                                         day: '2-digit',
                                         year: 'numeric',
                                      })
                                    : '-'}
                              </td>
                              <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                 {student?.churchOfBaptism || ''}
                              </td>
                              <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                 {student?.churchOfBaptismLocation || ''}
                              </td>
                              <td className='text-indigo-600'>
                                 {student?.baptismalCertificate?.src && (
                                    <a
                                       href={student.baptismalCertificate.src}
                                       target='_blank'
                                       rel='noreferrer'
                                    >
                                       <DocumentTextIcon className='h-5 w-5' />
                                    </a>
                                 )}
                              </td>
                           </tr>

                           <tr className='hover:bg-gray-100'>
                              <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6'>
                                 First Communion
                              </td>
                              {student?.firstCommunion?.toLowerCase() ===
                              'yes' ? (
                                 <td className='whitespace-nowrap px-3 py-4 text-sm text-green-500'>
                                    <svg
                                       xmlns='http://www.w3.org/2000/svg'
                                       className='h-6 w-6'
                                       fill='none'
                                       viewBox='0 0 24 24'
                                       stroke='currentColor'
                                       strokeWidth='2'
                                    >
                                       <path
                                          strokeLinecap='round'
                                          strokeLinejoin='round'
                                          d='M5 13l4 4L19 7'
                                       />
                                    </svg>
                                 </td>
                              ) : (
                                 <td className='whitespace-nowrap px-3 py-4 text-sm text-red-500'>
                                    <svg
                                       xmlns='http://www.w3.org/2000/svg'
                                       className='h-6 w-6'
                                       fill='none'
                                       viewBox='0 0 24 24'
                                       stroke='currentColor'
                                       strokeWidth='2'
                                    >
                                       <path
                                          strokeLinecap='round'
                                          strokeLinejoin='round'
                                          d='M6 18L18 6M6 6l12 12'
                                       />
                                    </svg>
                                 </td>
                              )}
                              <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                 {student?.firstCommunionDate
                                    ? getZoneDate(
                                         student.firstCommunionDate
                                      ).toLocaleDateString('en-us', {
                                         month: '2-digit',
                                         day: '2-digit',
                                         year: 'numeric',
                                      })
                                    : '-'}
                              </td>
                              <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                 {student?.churchOfFirstCommunion || ''}
                              </td>
                              <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                 {student?.churchOfFirstCommunionLocation || ''}
                              </td>
                              <td className='text-indigo-600'>
                                 {student?.firstCommunionCertificate?.src && (
                                    <a
                                       href={
                                          student.firstCommunionCertificate.src
                                       }
                                       target='_blank'
                                       rel='noreferrer'
                                    >
                                       <DocumentTextIcon className='h-5 w-5' />
                                    </a>
                                 )}
                              </td>
                           </tr>

                           <tr className='hover:bg-gray-100'>
                              <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6'>
                                 Confirmation
                              </td>
                              {student?.confirmation?.toLowerCase() ===
                              'yes' ? (
                                 <td className='whitespace-nowrap px-3 py-4 text-sm text-green-500'>
                                    <svg
                                       xmlns='http://www.w3.org/2000/svg'
                                       className='h-6 w-6'
                                       fill='none'
                                       viewBox='0 0 24 24'
                                       stroke='currentColor'
                                       strokeWidth='2'
                                    >
                                       <path
                                          strokeLinecap='round'
                                          strokeLinejoin='round'
                                          d='M5 13l4 4L19 7'
                                       />
                                    </svg>
                                 </td>
                              ) : (
                                 <td className='whitespace-nowrap px-3 py-4 text-sm text-red-500'>
                                    <svg
                                       xmlns='http://www.w3.org/2000/svg'
                                       className='h-6 w-6'
                                       fill='none'
                                       viewBox='0 0 24 24'
                                       stroke='currentColor'
                                       strokeWidth='2'
                                    >
                                       <path
                                          strokeLinecap='round'
                                          strokeLinejoin='round'
                                          d='M6 18L18 6M6 6l12 12'
                                       />
                                    </svg>
                                 </td>
                              )}
                              <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                 {student?.confirmationDate
                                    ? getZoneDate(
                                         student.confirmationDate
                                      ).toLocaleDateString('en-us', {
                                         month: '2-digit',
                                         day: '2-digit',
                                         year: 'numeric',
                                      })
                                    : '-'}
                              </td>
                              <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                 {student?.confirmationChurch || ''}
                              </td>
                              <td className='text-indigo-600'>
                                 {student?.confirmationCertificate?.src && (
                                    <a
                                       href={
                                          student.confirmationCertificate.src
                                       }
                                       target='_blank'
                                       rel='noreferrer'
                                    >
                                       <DocumentTextIcon className='h-5 w-5' />
                                    </a>
                                 )}
                              </td>
                           </tr>
                        </tbody>
                     </table>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
}
