import { HomeIcon } from '@heroicons/react/24/outline';
import SidebarItemsComp from '../Common/SidebarItemsComp';
import { NewDocumentIcon } from '../Religious_Education/SidebarItems';

const FuneralsSidebarItems = ({ setSidebarOpen, mobile }) => {
   const primarySidebarItems = [
      {
         name: 'Home',
         href: '/funerals',
         svg: HomeIcon,
         end: true,
      },
      {
         title: 'REPORTS',
         type: 'header',
         items: [
            {
               name: 'Notes Report',
               href: '/funerals/reports/notes',
               svg: NewDocumentIcon,
            },
         ],
      },
   ];

   return (
      <SidebarItemsComp
         mobile={mobile}
         primarySidebarItems={primarySidebarItems}
         setSidebarOpen={setSidebarOpen}
      />
   );
};

export default FuneralsSidebarItems;
