import { useState } from 'react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import {
   Combobox,
   ComboboxButton,
   ComboboxInput,
   ComboboxOption,
   ComboboxOptions,
   Label,
} from '@headlessui/react';

const ParentsCombobox = ({ items = [], selectedState, label }) => {
   const [query, setQuery] = useState('');
   const [selectedItem, setSelectedItem] = selectedState;

   const filteredItems =
      query === ''
         ? items
         : items.filter(item => {
              const fullName = `${item.firstname}${
                 item?.middlename ? ' ' + item.middlename : ''
              } ${item?.lastname ?? ''}`;

              return fullName
                 .toLowerCase()
                 .replace(/\s+/g, '')
                 .includes(query.toLowerCase().replace(/\s+/g, ''));
           });

   return (
      <Combobox
         as='div'
         className='mt-10'
         value={selectedItem}
         onChange={setSelectedItem}
      >
         {label && (
            <Label className='block text-sm font-medium text-gray-700'>
               {label}
            </Label>
         )}
         <div className='relative mt-1'>
            <ComboboxInput
               className='w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-12 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm'
               onChange={event => setQuery(event.target.value)}
               displayValue={
                  selectedItem
                     ? item =>
                          `${item.firstname}${
                             item?.middlename ? ` ${item.middlename}` : ''
                          } ${item?.lastname ?? ''}`
                     : ''
               }
            />
            <ComboboxButton className='absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none'>
               <ChevronUpDownIcon
                  className='h-5 w-5 text-gray-400'
                  aria-hidden='true'
               />
            </ComboboxButton>

            <ComboboxOptions className='absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'>
               {filteredItems.length === 0 && query !== '' ? (
                  <div className='relative cursor-default select-none py-2 px-4 text-gray-700'>
                     Nothing found.
                  </div>
               ) : (
                  filteredItems.map(item => (
                     <ComboboxOption
                        key={item._id}
                        value={item}
                        className='relative cursor-default select-none py-2 pl-3 pr-9 group text-gray-900 data-[focus]:bg-indigo-600 data-[focus]:text-white'
                     >
                        <span className='block truncate group-data-[selected]:font-semibold'>
                           {`${item.firstname}${
                              item?.middlename ? ` ${item.middlename}` : ''
                           } ${item?.lastname ?? ''}`}
                        </span>

                        <span className='absolute inset-y-0 right-0 hidden items-center pr-4 text-indigo-600 group-data-[selected]:flex group-data-[focus]:text-white'>
                           <CheckIcon className='h-5 w-5' aria-hidden='true' />
                        </span>
                     </ComboboxOption>
                  ))
               )}
            </ComboboxOptions>
         </div>
      </Combobox>
   );
};

export default ParentsCombobox;
