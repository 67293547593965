import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { Component } from 'react';
import { Link, Outlet } from 'react-router-dom';

export default class ErrorBoundaryClass extends Component {
   constructor(props) {
      super(props);
      this.state = { error: false };
   }

   componentDidCatch(error) {
      this.setState({ error });
   }

   render() {
      if (this.state.error && this.props?.fallback) return this.props.fallback;
      if (this.state.error) {
         return (
            <div className='p-10'>
               <h2 className='text-2xl text-red-700 flex gap-1 items-center justify-start'>
                  <ExclamationTriangleIcon className='text-red-700 h-8 w-8' />
                  Something went wrong.
               </h2>
               <p className='whitespace-pre-wrap mt-2 ml-2 text-red-700'>
                  {this.state.error && this.state.error.toString()}
               </p>

               <div className='mt-5 flex space-x-3 sm:border-l sm:border-transparent sm:pl-2'>
                  <Link
                     to={-1}
                     onClick={() => {
                        this.setState({ error: false });
                     }}
                     className='inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                  >
                     Go back{' '}
                  </Link>
               </div>
            </div>
         );
      }
      return <Outlet />;
   }
}
