import { useState, useEffect, useCallback } from 'react';
import useAuth from '../../hooks/useAuth';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import logoSvg from '../../assets/Artboard_1.svg';
import jwtDecode from 'jwt-decode';

import axios from '../../api/axios';
import { toast } from 'react-toastify';
import { LockClosedIcon } from '@heroicons/react/20/solid';
const LOGIN_URL = '/auth/login';

const Login = () => {
   const [loading, setLoading] = useState(false);
   const { setAuth, auth } = useAuth();
   const navigate = useNavigate();

   const location = useLocation();
   const from = location.state?.from?.pathname;

   const urlToRedirect = useCallback(
      (accessToken, moduleOnLogin) => {
         const decoded = accessToken ? jwtDecode(accessToken) : undefined;
         const roles = decoded?.UserInfo?.roles || [];

         if (from && from !== '/') return from;
         if (moduleOnLogin) {
            if (moduleOnLogin.toLowerCase() === 'dashboard')
               return '/dashboard';
            if (moduleOnLogin.toLowerCase() === 'weddings') return '/weddings';
            if (moduleOnLogin.toLowerCase() === 'religious_education')
               return '/religious-education';
            if (moduleOnLogin.toLowerCase() === 'website') return '/website';
         }
         if (roles.length > 0) {
            if (roles.includes(2120)) return '/super-admin';
            if (roles.includes(1210)) return '/dashboard';
            if (roles.includes(1215)) return '/religious-education';
            if (roles.includes('parent')) return '/re/parent';
            if (roles.includes('teacher')) return '/re/teacher';
            return '/dashboard';
         } else return '/dashboard';
      },
      [from]
   );

   useEffect(() => {
      if (auth?.accessToken) {
         navigate(urlToRedirect(auth.accessToken, auth?.moduleOnLogin), {
            replace: true,
         });
      }
   }, [auth?.accessToken, navigate, urlToRedirect, auth?.moduleOnLogin]);

   const [user, setUser] = useState('');
   const [pwd, setPwd] = useState('');
   const handleSubmit = async e => {
      e.preventDefault();

      if (!user || !pwd)
         return toast.error('Username and Password is required!');
      try {
         setLoading(true);
         const response = await axios.post(
            LOGIN_URL,
            JSON.stringify({ user, pwd }),
            {
               headers: { 'Content-Type': 'application/json' },
               withCredentials: true,
            }
         );

         const accessToken = response?.data?.accessToken;
         const church = response?.data?.church;
         const firstname = response?.data?.firstname;
         const lastname = response?.data?.lastname;
         const photo = response?.data?.photo;
         const moduleOnLogin = response?.data?.moduleOnLogin;
         const churchID = response?.data?.churchID;
         setAuth({
            user: response?.data?.user,
            accessToken,
            church,
            firstname,
            lastname,
            ...(response?.data?.middlename && {
               middlename: response.data.middlename,
            }),
            ...(photo && { photo }),
            ...(moduleOnLogin && { moduleOnLogin }),
            ...(churchID && { churchID }),
         });
         setUser('');
         setPwd('');

         navigate(urlToRedirect(accessToken, moduleOnLogin), {
            replace: true,
         });
      } catch (err) {
         if (err?.code === 'ERR_NETWORK') {
            toast.error('No Server Response');
         } else if (err.response?.status === 400) {
            toast.error('Missing Username or Password');
         } else if (err.response?.status === 401) {
            toast.error('Invalid Credentials');
         } else if (err.response?.data?.message) {
            toast.error(err.response.data.message);
         } else {
            toast.error('Login Failed');
         }
      } finally {
         setLoading(false);
      }
   };

   //    const togglePersist = () => {
   //       setPersist(prev => !prev);
   //   }

   useEffect(() => {
      const body = document.querySelector('body');
      body.classList.add('bg-gray-700');

      return () => {
         body.classList.remove('bg-gray-700');
      };
   }, []);

   return (
      <>
         <div className='flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8 bg-gray-700'>
            <div className='w-full max-w-md space-y-8 mt-20'>
               {location.state?.registeredNow ? (
                  <div>
                     <Link to='/'>
                        <img
                           className='mx-auto h-12 w-auto'
                           src={logoSvg}
                           alt='Sacramatic'
                        />
                     </Link>
                     <h2 className='mt-6 text-center text-3xl font-bold tracking-tight block bg-gradient-to-r from-teal-200 to-cyan-400 bg-clip-text text-transparent sm:pb-5'>
                        Congratulations!
                     </h2>
                     <p className='mt-2 text-center text-sm text-white'>
                        <span className='mt-2'>
                           your account has been created and your 30 day trial
                           is active
                        </span>
                     </p>
                     <p className='text-center font-medium text-cyan-400 hover:text-teal-200 mt-2'>
                        login and get started!
                     </p>
                  </div>
               ) : (
                  <div>
                     <Link to='/'>
                        <img
                           className='mx-auto h-12 w-auto'
                           src={logoSvg}
                           alt='Sacramatic'
                        />
                     </Link>
                     <h2 className='mt-6 text-center text-3xl font-bold tracking-tight block bg-gradient-to-r from-teal-200 to-cyan-400 bg-clip-text pb-3 text-transparent sm:pb-5'>
                        Sign in to your account
                     </h2>
                     <p className='mt-2 text-center text-sm text-white'>
                        Or{' '}
                        <Link
                           to='/register'
                           className='font-medium text-cyan-400 hover:text-teal-200'
                        >
                           start your 30-day free trial
                        </Link>
                     </p>
                  </div>
               )}
               <form className='mt-8 space-y-6' onSubmit={handleSubmit}>
                  <input type='hidden' name='remember' value='true' />
                  <div className='-space-y-px rounded-md shadow-sm'>
                     <div>
                        <label htmlFor='email-address' className='sr-only'>
                           Email address
                        </label>
                        <input
                           id='email-address'
                           name='email'
                           type='text'
                           autoComplete='email'
                           onChange={e => setUser(e.target.value)}
                           value={user}
                           required
                           className='relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
                           placeholder='Email address'
                        />
                     </div>
                     <div>
                        <label htmlFor='password' className='sr-only'>
                           Password
                        </label>
                        <input
                           id='password'
                           name='password'
                           type='password'
                           onChange={e => setPwd(e.target.value)}
                           value={pwd}
                           autoComplete='current-password'
                           required
                           className='relative block w-full appearance-none rounded-none rounded-b-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
                           placeholder='Password'
                        />
                     </div>
                  </div>

                  <div className='flex items-center justify-between'>
                     <div className='flex items-center'>
                        <input
                           id='remember-me'
                           name='remember-me'
                           type='checkbox'
                           // onChange={togglePersist}
                           // checked={persist}
                           className='h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500'
                        />
                        <label
                           htmlFor='remember-me'
                           className='ml-2 block text-sm text-white'
                        >
                           Remember me
                        </label>
                     </div>

                     <div className='text-sm'>
                        <Link
                           to='/forgotten-password'
                           state={user ? { user } : undefined}
                           className='font-medium text-cyan-400 hover:text-teal-200'
                        >
                           Forgot your password?
                        </Link>
                     </div>
                  </div>

                  <div>
                     <button
                        disabled={loading}
                        type='submit'
                        className='disabled:cursor-wait group relative flex w-full justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-sm font-medium text-white hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                     >
                        <span className='absolute inset-y-0 left-0 flex items-center pl-3'>
                           {/* <!-- Heroicon name: mini/lock-closed --> */}
                           {loading ? (
                              <div className='bg-transparent h-5 w-5 border-2 border-gray-900 border-t-transparent rounded-full animate-spin'></div>
                           ) : (
                              <LockClosedIcon className='h-5 w-5 text-gray-900 group-hover:white' />
                           )}
                        </span>
                        Sign in
                     </button>
                  </div>
               </form>
            </div>
         </div>
      </>
   );
};

export default Login;
