import { CreditCardIcon } from '@heroicons/react/24/outline';
import SidebarItemsComp from '../Common/SidebarItemsComp';

const BillingSidebarItems = ({ setSidebarOpen, mobile }) => {
   const primarySidebarItems = [
      {
         name: 'Subscriptions',
         href: '/billing/subscriptions',
         svg: CreditCardIcon,
      },
   ];

   return (
      <SidebarItemsComp
         mobile={mobile}
         primarySidebarItems={primarySidebarItems}
         setSidebarOpen={setSidebarOpen}
      />
   );
};

export default BillingSidebarItems;
