import { HomeIcon } from '@heroicons/react/20/solid';
import { Link, Navigate } from 'react-router-dom';
import useAuth from '../../../hooks/useAuth';
import { useTeacherContext } from '../../../context/ReligiousEducation/TeacherContext';
import { useQuery } from '@tanstack/react-query';
import ClassesTable from './ClassesTable';
import PageSpinner from '../../../components/UI/PageSpinner';
import stringToUrlString from '../../../utils/stringToUrlString';

export default function Classes() {
   const { auth } = useAuth();
   const { getClasses } = useTeacherContext();

   const classesQuery = useQuery({
      queryFn: getClasses,
      queryKey: ['classes'],
   });

   if (classesQuery.isLoading) return <PageSpinner />;

   if (classesQuery.data?.length === 1)
      return (
         <Navigate
            to={`${stringToUrlString(classesQuery.data[0].className)}`}
            replace
         />
      );

   return (
      <main className='flex-1'>
         {/* <!-- BREADCRUMBS --> */}
         <nav
            className='bg-white border-b border-gray-200 flex'
            aria-label='Breadcrumb'
         >
            <ol className='max-w-screen-xl w-full mx-auto px-4 flex space-x-4 sm:px-6 lg:px-8'>
               <li className='flex'>
                  <div className='flex items-center'>
                     <Link to='/' className='text-gray-400 hover:text-gray-500'>
                        {/* <!-- Heroicon name: mini/home --> */}
                        <HomeIcon className='flex-shrink-0 h-5 w-5' />
                        <span className='sr-only'>Home</span>
                     </Link>
                  </div>
               </li>

               <li className='flex'>
                  <div className='flex items-center'>
                     <svg
                        className='flex-shrink-0 w-6 h-full text-gray-200'
                        viewBox='0 0 24 44'
                        preserveAspectRatio='none'
                        fill='currentColor'
                        xmlns='http://www.w3.org/2000/svg'
                        aria-hidden='true'
                     >
                        <path d='M.293 0l22 22-22 22h1.414l22-22-22-22H.293z' />
                     </svg>
                     <Link
                        to='/re/teacher'
                        className='ml-4 text-sm font-light text-gray-500 hover:text-gray-700'
                     >
                        Teacher Dashboard
                     </Link>
                  </div>
               </li>

               <li className='flex'>
                  <div className='flex items-center'>
                     <svg
                        className='flex-shrink-0 w-6 h-full text-gray-200'
                        viewBox='0 0 24 44'
                        preserveAspectRatio='none'
                        fill='currentColor'
                        xmlns='http://www.w3.org/2000/svg'
                        aria-hidden='true'
                     >
                        <path d='M.293 0l22 22-22 22h1.414l22-22-22-22H.293z' />
                     </svg>
                     <span className='ml-4 text-sm font-medium text-gray-500 hover:text-gray-700'>
                        Classes
                     </span>
                  </div>
               </li>
            </ol>
            <div className='mr-10 mt-3'>
               <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  strokeWidth='1.5'
                  stroke='currentColor'
                  className='w-6 h-6'
               >
                  <path
                     strokeLinecap='round'
                     strokeLinejoin='round'
                     d='M10.5 6h9.75M10.5 6a1.5 1.5 0 11-3 0m3 0a1.5 1.5 0 10-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-9.75 0h9.75'
                  />
               </svg>
            </div>
         </nav>

         {/* <!-- Pinned projects --> */}
         <div className='px-4 mt-6 sm:px-6 lg:px-8'>
            <h1 className='text-xl font-semibold text-gray-900'>
               Classes for {'church' in auth ? auth.church : ''}
            </h1>
         </div>

         {/* <!--table with names--> */}
         <div className='px-4 sm:px-6 lg:px-8'>
            <div className='sm:flex sm:items-center'>
               <div className='sm:flex-auto'>
                  <p className='mt-2 text-sm text-gray-700'>
                     From here you can create and manage classes.
                  </p>
               </div>
            </div>
            <div className='mt-10 mb-40 flex flex-col'>
               <div className='-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8'>
                  <div className='inline-block min-w-full py-2 align-middle md:px-6 lg:px-8'>
                     <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
                        {/* Table */}
                        {classesQuery.isLoading ? (
                           <div className='px-6 py-3 text-sm font-bold'>
                              Loading...
                           </div>
                        ) : classesQuery?.data != null ? (
                           <ClassesTable classes={classesQuery.data} />
                        ) : null}
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </main>
   );
}
