import {
   FilmIcon,
   HomeIcon,
   MapIcon,
   BuildingOfficeIcon,
   UserIcon,
} from '@heroicons/react/24/outline';
import SidebarItemsComp from '../Common/SidebarItemsComp';
import { StoreSVG } from '../GiftStore/SidebarItems';

const UsersIconOutline = () => (
   <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      stroke='currentColor'
      className='text-gray-400 group-hover:text-gray-500 mr-3 flex-shrink-0 h-6 w-6'
   >
      <path
         strokeLinecap='round'
         strokeLinejoin='round'
         d='M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z'
      />
   </svg>
);
const UserGroupIcon = () => (
   <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      stroke='currentColor'
      className='text-gray-400 group-hover:text-gray-500 mr-3 flex-shrink-0 h-6 w-6'
   >
      <path
         strokeLinecap='round'
         strokeLinejoin='round'
         d='M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z'
      />
   </svg>
);
export const NewDocumentIcon = () => (
   <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      stroke='currentColor'
      className='text-gray-400 group-hover:text-gray-500 mr-3 flex-shrink-0 h-6 w-6'
   >
      <path
         strokeLinecap='round'
         strokeLinejoin='round'
         d='M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z'
      />
   </svg>
);
const AcademicCapIcon = () => (
   <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      stroke='currentColor'
      className='text-gray-400 group-hover:text-gray-500 mr-3 flex-shrink-0 h-6 w-6'
   >
      <path
         strokeLinecap='round'
         strokeLinejoin='round'
         d='M4.26 10.147a60.436 60.436 0 00-.491 6.347A48.627 48.627 0 0112 20.904a48.627 48.627 0 018.232-4.41 60.46 60.46 0 00-.491-6.347m-15.482 0a50.57 50.57 0 00-2.658-.813A59.905 59.905 0 0112 3.493a59.902 59.902 0 0110.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.697 50.697 0 0112 13.489a50.702 50.702 0 017.74-3.342M6.75 15a.75.75 0 100-1.5.75.75 0 000 1.5zm0 0v-3.675A55.378 55.378 0 0112 8.443m-7.007 11.55A5.981 5.981 0 006.75 15.75v-1.5'
      />
   </svg>
);
const DocumentTextIcon = () => (
   <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      stroke='currentColor'
      className='text-gray-400 group-hover:text-gray-500 mr-3 flex-shrink-0 h-6 w-6'
   >
      <path
         strokeLinecap='round'
         strokeLinejoin='round'
         d='M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z'
      />
   </svg>
);

type Props = {
   setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
   mobile?: boolean;
};

export default function DashboardSidebarItems({
   setSidebarOpen,
   mobile,
}: Props) {
   const primarySidebarItems = [
      {
         name: 'Home',
         href: '/dashboard',
         svg: HomeIcon,
         end: true,
      },
      {
         name: 'Training',
         href: '/dashboard/video-training',
         svg: FilmIcon,
      },
      {
         name: 'Baptisms',
         href: '/baptisms',
         svg: UsersIconOutline,
      },
      {
         name: 'Funerals',
         href: '/funerals',
         svg: UsersIconOutline,
      },
      {
         name: 'Religious Education',
         href: '/religious-education',
         svg: AcademicCapIcon,
      },
      {
         name: 'Weddings',
         href: '/weddings',
         svg: UsersIconOutline,
      },
      { name: 'Gift Store', href: '/gift-store', svg: StoreSVG },
      {
         title: 'PARISH',
         type: 'header',
         items: [
            {
               name: 'Events',
               href: '/website',
               svg: MapIcon,
            },
            {
               name: 'Social Media',
               href: '/social-media',
               svg: UserIcon,
            },
            {
               name: 'Website',
               href: '/website',
               svg: UserGroupIcon,
            },
         ],
      },
      {
         title: 'Support',
         type: 'header',
         items: [
            {
               name: 'How To',
               href: '/how-to',
               svg: DocumentTextIcon,
            },
            {
               name: 'Contact Us',
               href: '/contact',
               svg: BuildingOfficeIcon,
            },
         ],
      },
   ];

   return (
      <SidebarItemsComp
         mobile={mobile}
         primarySidebarItems={primarySidebarItems}
         setSidebarOpen={setSidebarOpen}
      />
   );
}
